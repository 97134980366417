@import 'variables';
@import "~src/styleguide/2.0/images";

a, a:visited {
  font-family: $font-ibm_plex_sans;
  font-weight: $font-weight-medium;
  font-style: normal;
  text-decoration: none;
  color: $color-helder-red-text;

  &:hover {
    text-decoration: underline;
  }
}

a.read-more {
  color: $color-helder-red-text;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 16px;
    height: 21px;
    background: transparent svg-url($svg-image-arrow-right) no-repeat center center;
    top: 0;
    right: -25px;
    transition: $animation-default;
  }

  &:hover {
    &:after {
      right: -32px;
    }
  }
}

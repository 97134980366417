@import 'variables';

dl {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: $font-size-default;

  dt {
    padding: 0;
    box-sizing: border-box;
    font-weight: 600;
    margin: 0 0 $space-s 0;
  }

  dd {
    margin: 0 0 $space-l 0;
  }
}

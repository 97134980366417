@import 'variables';

body {
  font-family: $font-ibm_plex_sans;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;

  background-color: $color-white;
  color: $color-heritage-blue;
  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0;

  &, p {
    font-family: $font-ibm_plex_sans;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-default;
    line-height: 160%; // = 24px at font-size 15px
  }
}

p {
  margin: 0 0 1em;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: $font-objectivity;
  line-height: 120%;
  color: $color-heritage-blue;
}

h1 {
  font-size: 36px;
  line-height: 42px;
  font-weight: 600;
  letter-spacing: -0.02em;
  margin-bottom: 0.6em;
}

h2 {
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: -0.02em;
  margin-bottom: 0.6em;
}

h3 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: -0.02em;
  margin-bottom: 0.3em;
}

h4 {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.01em;
  margin-bottom: 0.2em;
}

h5 {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: -0.01em;
  margin-bottom: 0.2em;
}

.external-link {
  text-decoration: underline;
  color: $color-helder-blue;
  font-weight: 500;
  margin: 20px 0;
  display: block;
}

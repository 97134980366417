/*------------------------------------*\
  #FONTS
\*------------------------------------*/
$font-ibm_plex_sans: "IBM Plex Sans", Helvetica, arial, sans-serif;
$font-objectivity: "Objectivity", Helvetica, arial, sans-serif;

$font-default: $font-ibm_plex_sans;

$font-size-extra-large: 28px;
$font-size-large: 20px;
$font-size-default: 15px;
$font-size-small: 14px;
$font-size-extra-small: 10px;

$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-bold: 700;

/*------------------------------------*\
  #COLORS
\*------------------------------------*/
$color-black: #000000;
$color-heritage-blue: #023A4F;
$color-heritage-blue-75: #023a4fbf;
$color-helder-blue: #1489D8;
$color-helder-red: #F35F86;
$color-yellow-light: #fff7eb;
$color-yellow: #cec56a;
$color-light-gray: #F6F8F9;
$color-lightest-gray: #F8FAFD;
$color-light-blue: #D1E6F2;
$color-gray: #BABCBD;
$color-white: #FFFFFF;
$color-light-pink: #FEECF0;
$color-helder-red-text: #DA1050; // Red text should use this color; contrast-value of 5.03
$color-header: #aaebfb;

/*------------------------------------*\
  #ANIMATIONS
\*------------------------------------*/
$animation-default: all 0.20s ease-in;

/*------------------------------------*\
  #BORDERS
\*------------------------------------*/
$border-radius-large: 24px;
$border-radius-medium: 16px;
$border-radius-small: 8px;
$border-radius-extra-small: 4px;

$box-shadow-medium: 0 2px 15px #d8dbe4;
$box-shadow-dark: 0 2px 15px #737479;

/*------------------------------------*\
  #SPACE
\*------------------------------------*/
$space-default: 16px;
$space-xxs: 2px;
$space-xs: 4px;
$space-s: 8px;
$space-m: 16px;
$space-l: 32px;
$space-xl: 64px;
$space-xxl: 128px;

/*------------------------------------*\
  #MEDIA
\*------------------------------------*/
$small-breakpoint: 40em;
$medium-breakpoint: 64em;
$large-breakpoint: 80em;
$extra-large-breakpoint: 90em;

$small-only: "screen and (max-width: #{$small-breakpoint})";
$medium-only: "screen and (min-width: #{$medium-breakpoint}) and (max-width: #{$large-breakpoint})";

$small-up: "screen and (min-width: #{$small-breakpoint})";
$medium-up: "screen and (min-width: #{$medium-breakpoint})";
$large-up: "screen and (min-width:#{$large-breakpoint})";
$extra-large-up: "screen and (min-width:#{$extra-large-breakpoint})";

@import 'variables';

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.clickable {
  cursor: pointer;
}

.pull-left {
  float: left;
}

.clear-fix {
  clear: both;
}

@media #{$small-up} {
  .hide-on-desktop {
    display: none !important;
  }
}

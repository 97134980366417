@import 'variables';

.button, .button:visited {
  display: inline-block;
  height: 48px;
  line-height: 48px;
  border-radius: $border-radius-extra-small;
  background-color: $color-helder-blue;
  padding: 0 $space-m;
  font-family: $font-objectivity;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: $color-white;
  border: 1px solid transparent;
  cursor: pointer;
  transition: $animation-default;

  &:hover, &:focus {
    background-color: $color-helder-red;
    text-decoration: none;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $color-gray;
  }

  &.button--primary {
  }

  &.button--secondary {
    background-color: $color-white;
    color: $color-helder-blue;
    border-color: $color-helder-blue;

    &:hover, &:focus {
      color: $color-helder-red;
      border-color: $color-helder-red;
    }
  }

  &.button--focus {
    background-color: $color-helder-red;

    &:hover, &:focus {
      background-color: $color-helder-blue;
    }
  }

  &.button--small {
    padding: 0 $space-s;
    height: 32px;
    line-height: 32px;
  }
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('~assets/fonts/ibm-plex-sans/IBMPlexSans.woff2') format('woff2'),
  url('~assets/fonts/ibm-plex-sans/IBMPlexSans.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('~assets/fonts/ibm-plex-sans/IBMPlexSans-Medium.woff2') format('woff2'),
  url('~assets/fonts/ibm-plex-sans/IBMPlexSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('~assets/fonts/ibm-plex-sans/IBMPlexSans-SemiBold.woff2') format('woff2'),
  url('~assets/fonts/ibm-plex-sans/IBMPlexSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('~assets/fonts/ibm-plex-sans/IBMPlexSans-Bold.woff2') format('woff2'),
  url('~assets/fonts/ibm-plex-sans/IBMPlexSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Objectivity';
  src: url('~assets/fonts/objectivity/Objectivity-Medium.woff2') format('woff2'),
  url('~assets/fonts/objectivity/Objectivity-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Objectivity';
  src: url('~assets/fonts/objectivity/Objectivity-Bold.woff2') format('woff2'),
  url('~assets/fonts/objectivity/Objectivity-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Objectivity';
  src: url('~assets/fonts/objectivity/Objectivity-BoldSlanted.woff2') format('woff2'),
  url('~assets/fonts/objectivity/Objectivity-BoldSlanted.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Objectivity';
  src: url('~assets/fonts/objectivity/Objectivity-ExtraBold.woff2') format('woff2'),
  url('~assets/fonts/objectivity/Objectivity-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'healthcare-icons';
  src: url('~assets/fonts/healthcare-icons/line-icons.eot'),
  url('~assets/fonts/healthcare-icons/line-icons.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'healthcare-small-icons';
  src: url('~assets/fonts/healthcare-icons/small-icons.eot'),
  url('~assets/fonts/healthcare-icons/small-icons.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
